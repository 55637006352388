export const profileActionType = {
    UPDATE_PROFILE_DETAILS: 'UPDATE_PROFILE_DETAILS'
}

export const searchActionType = {
    SET_PREDICTION_SEARCH: 'SET_PREDICTION_SEARCH'
}

export const reloadActionType = {
    RELOAD: 'RELOAD'
}