import React from 'react';
import { useDispatch } from 'react-redux';
import { RELOAD } from 'store/actions/reload';
import { Layout, Menu } from 'antd';
import {
  DesktopOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import logo from 'assets/images/logo-scgc.png';
import Link from 'components/Link';

const useEffect = React.useEffect;

const { Sider } = Layout;

interface SidebarProps {
  pathname: string
}

export const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
  const dispatch = useDispatch();
  const pathname = props.pathname;
  const [current, setCurrent] = React.useState('home');
  const handleClick = e => {
    setCurrent(e.key);
    dispatch(RELOAD(true));
  };

  useEffect(() => {
    // Should not ever set state during rendering, so do this in useEffect instead.
    // console.log('pathname==>', pathname);
    if (pathname === '/prediction/full-hybrid') {
      setCurrent('prediction');
    } else if (pathname === '/prediction/close-gap') {
      setCurrent('3');
    } else if (pathname === '/prediction/final-properties') {
      setCurrent('4');
    } else {
      setCurrent('home');
    }
  }, [pathname]);

  return (
    <Sider collapsible>
      <img className='logo' src={logo} alt="Logo" />
      <Menu theme="light" mode="inline" defaultSelectedKeys={['1']} selectedKeys={[current]} onClick={handleClick}>
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="prediction" icon={<DesktopOutlined />}>
          <Link to="/prediction/full-hybrid">Full Hybrid</Link>
        </Menu.Item>
        {/* <Menu.Item key="3" icon={<DesktopOutlined />}>
          <Link to="/prediction/close-gap">Close Gap</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<DesktopOutlined />}>
          <Link to="/prediction/final-properties">Final Properties</Link>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
}
