import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PROFILE_DETAILS } from 'store/actions/profile';
import { SET_PREDICTION_SEARCH } from 'store/actions/search';
import { RELOAD } from 'store/actions/reload';
import API from 'service/api';
import config from "config";
import { Layout, Row, Col, Avatar, Menu, Dropdown, Input, Space, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const useState = React.useState;

const { Header } = Layout;
const { Search } = Input;

interface HeaderProps {
}

export const HeaderCom: React.FunctionComponent<HeaderProps> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile: any = useSelector((state: any) => state.profile);
  const search: any = useSelector((state: any) => state.search);
  const reload: any = useSelector((state: any) => state.reload);
  // const [loading, setLoading] = useState(true);
  const [workers, setWorkers] = useState();
  const [isLogout, setIsLogout] = useState(false);

  const GetUserDetail = async () => {
    try {
      if (localStorage.getItem('authToken')) {
        const { data } = await API({
          url: config.service.SSO_GETUSERDETAIL_URL,
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('authToken')
          }
        });
        
        if (data?.role === null && localStorage.getItem('hasRole') !== '1') {
          localStorage.removeItem('authToken');
          localStorage.removeItem('refresh_token');
          window.location.href = '/unauthorized/401';
        }
        
        dispatch(UPDATE_PROFILE_DETAILS(data));
      } else {
          window.location.href = '/login'; 
      }
    } catch (error: any) {
      window.location.href = '/unauthorized/401';
    }
  };

  const getAllWorker = async () => {
    // console.log('=====getAllWorker=====');
    try {
      const { data } = await API({
        url: `${config.server.SERVER_URL}/v1/worker`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('authToken')
        }
      });

      let itemList: any =[];
      data.forEach((worker: any, i: number) => {
        const bgColor: string = worker.available && worker.active ? 'bg-success': !worker.available && worker.active ? 'bg-warning': 'bg-secondary'
        const id = worker._id;
        itemList.push(
          <Menu.Item key={id}>
            <Space>
              <div className={`circle ${bgColor}`}></div> { worker.name }
            </Space>
          </Menu.Item>
        );
      })
      // console.log('itemList==>', itemList);
      setWorkers(itemList);
      if (history.location.pathname === '/prediction/full-hybrid') {
        dispatch(RELOAD(false));
      }
    } catch (error) {
      console.log('error', error);
      if (history.location.pathname === '/prediction/full-hybrid') {
        dispatch(RELOAD(false));
      }
    }
  };
  
  const handleLogout = () => {
    setIsLogout(true);
    const data = {
      id: null,
      username: null,
      address: null,
      email: null,
      tel: null,
      configApp: {},
      information: {},
      photo: {},
      role: {},
    };
    dispatch(UPDATE_PROFILE_DETAILS(data));
  };
  const menu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/logout">Logout</a>
      </Menu.Item>
    </Menu>
  );

  const menuInstance = (
    <Menu>
      <Spin spinning={reload}>
        { workers }
      </Spin>
    </Menu>
  );
  

  const onSearch = async (value) => {
    dispatch(SET_PREDICTION_SEARCH(value));
    dispatch(RELOAD(true));
    history.push('/search');
  };
  const handleChangeSearch = (e) => {
    dispatch(SET_PREDICTION_SEARCH(e.target.value));
  };
  

  useEffect(() => {
    if (profile.id === null && !isLogout) {
      GetUserDetail();
    }
    if (reload === true && profile.id !== null) {
      getAllWorker();
    }
  }, [profile, reload]);

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <Row justify="end">
        <Col span={8} lg={{ span: 5 }}>
          <Dropdown overlay={menuInstance} placement="bottomRight" trigger={['click']} className='float-right mr-2'>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              <Space>
                Instance Model
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Col>
        <Col span={8} lg={{ span: 5 }} className="text-end">
            <Search placeholder="input name model" onSearch={onSearch} allowClear enterButton value={search} onChange={handleChangeSearch} className='w-100 mt-3' />
        </Col>
        <Col span={4} lg={{ span: 2 }} className="text-center">
          <Dropdown overlay={menu} placement="bottomRight" trigger={['click']} arrow={true}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <Avatar src={profile.photo.image || "https://joeschmoe.io/api/v1/random"} />
            </a>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
}