import { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import config from 'config';
import API from 'service/api';
import API_BOOK from "service/endpoints";

const Home = lazy(() => import('pages/Home'));
const Search = lazy(() => import('pages/Search'));
const Prediction = lazy(() => import('pages/Prediction'));
// const FullHybrid = lazy(() => import('pages/FullHybrid'));
// const CloseGap = lazy(() => import('pages/CloseGap'));
// const FinalProperties = lazy(() => import('pages/FinalProperties'));
const Unauthorized = lazy(() => import('components/Feedback/unauthorized'));
const NotFound = lazy(() => import('components/Feedback/NotFound'));

type routeItem = {
    path: string,
    key: string,
    exact: boolean,
    component: Function,
}

type routes = routeItem & {
    routes?: routeItem[]
}

const ROUTES: routes[] = [
    {
        path: "/",
        key: "ROOT",
        exact: true,
        component: (props: any) => {
            if (!localStorage.getItem('authToken')) {
                return <Redirect to={"/login"} />;
            }
            return <RenderRoutes {...props} />;
        },
        routes: [
            {
                path: "/",
                key: "ROOT",
                exact: true,
                component: Home,
            }
        ]
    },
    {
        path: "/search",
        key: "Search",
        exact: true,
        component: (props: any) => {
            if (!localStorage.getItem('authToken')) {
                return <Redirect to={"/login"} />;
            }
            return <RenderRoutes {...props} />;
        },
        routes: [
            {
                path: "/search",
                key: "Search",
                exact: true,
                component: Search,
            }
        ]
    },
    {
        path: "/prediction/:model",
        key: "Prediction",
        exact: true,
        component: (props: any) => {
            if (!localStorage.getItem('authToken')) {
                return <Redirect to={"/login"} />;
            }
            return <RenderRoutes {...props} />;
        },
        routes: [
            {
                path: "/prediction/:model",
                key: "Prediction",
                exact: true,
                component: Prediction,
            }
        ]
    },
    {
        path: "/login",
        key: "LOGIN",
        exact: true,
        component: () => {
            const url: any = config.service.SSO_URL || '';
            window.location.href = url;
            
            return false;
        },
        routes: []
    },
    {
        path: "/auth",
        key: "AUTH",
        exact: true,
        component: () => {
            const queryParams = new URLSearchParams(window.location.search);
            const access_token = queryParams.get('access_token') || '';
            const refresh_token = queryParams.get('refresh_token') || '';
            if (access_token && refresh_token) {
                localStorage.setItem('authToken', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('hasRole', '1');
            }
            return <Redirect to={"/"} />;
        },
        routes: []
    },
    {
        path: "/logout",
        key: "LOGOUT",
        exact: true,
        component: () => {
            API({
                url: API_BOOK.REVOKE_API,
                method: 'DELETE',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('authToken')
                },
            }).then(() => {
                localStorage.removeItem('authToken');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('hasRole');
                const url: any = config.service.SSO_LOGOUT_URL || '';
                window.location = url;
            }).catch((err) => {
                console.log('err==>', err);
            });
            return <Redirect to={"/"} />;
        },
        routes: []
    },
    {
        path: "/unauthorized/401",
        key: "UNAUTHORIZED",
        exact: true,
        component: Unauthorized,
        routes: []
    },
];

export default ROUTES


export function RenderRoutes({ routes }: { routes: routes[] }) {
    
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes {...route} />;
            })}

            <Route component={NotFound} />
        </Switch>
    );
}

function RouteWithSubRoutes(route: routes) {
    return (
        <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            render={props => <route.component {...props} routes={route.routes} />}
        />
    );
}
