import { searchActionType } from '../actionTypes'

const reducers = (state = '', action) => {
    switch (action.type) {
        case searchActionType.SET_PREDICTION_SEARCH:
            return action.payload;
        default:
            return state;
    }
}

export default reducers;