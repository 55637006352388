import config from 'config';

let endpoints = {
	//SSO
	REVOKE: config.service.SSO_REVOKE_URL,
	LOGOUT: config.service.SSO_LOGOUT_URL,
	UPDATE_PROFILE: config.service.SSO_GETUSERDETAIL_URL,
}

let API_BOOK = {
	UPDATE_PROFILE: {
		url: endpoints.UPDATE_PROFILE,
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('authToken')
		}
	},
	REVOKE_API: {
		url: endpoints.REVOKE,
		method: 'DELETE',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('authToken')
		}
	},
	LOGOUT_API: {
		url: endpoints.LOGOUT,
		method: 'GET',
	}
}

export default API_BOOK


