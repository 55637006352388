let env = process.env;
const config = {
    deviceType: 'web',
    version: env.REACT_APP_SERVICE_NODE_ENV,
    service: {
        BASE_URL: env.REACT_APP_SERVICE_BASE_URL,
        SSO_URL: env.REACT_APP_SERVICE_SSO_URL,
        SSO_REVOKE_URL: env.REACT_APP_SERVICE_SSO_REVOKE_URL,
        SSO_LOGOUT_URL: env.REACT_APP_SERVICE_SSO_LOGOUT_URL,
        SSO_GETUSERDETAIL_URL: env.REACT_APP_SERVICE_SSO_GETUSERDETAIL_URL,
    },
    server: {
        SERVER_URL: env.REACT_APP_SERVICE_SERVER_URL,
        FILE_URL: env.REACT_APP_SERVICE_FILE_URL,
        PATH_DOWNLOAD_TEMPLATE: env.REACT_APP_SERVICE_PATH_DOWNLOAD_TEMPLATE,
    }
};
export default config;
