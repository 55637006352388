import React from 'react'
import { ThemeProvider } from "styled-components";
// import themes from 'constants/theme'
import { Router } from "react-router-dom";
import Routes from './routes'
import store from 'store'
import { Provider } from 'react-redux'
import GlobalStyle from './styles/globalStyle'
import ErrorBoundary from 'components/ErrorBoundaries';

import { history } from 'helpers/common';
import { Layout } from 'antd';
import Sidebar from 'components/Sidebar';
import HeaderCom from 'components/HeaderCom';
import FooterCom from 'components/FooterCom';
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import theme from './styles/theme'

const { Content } = Layout;

const App: React.FunctionComponent = () => {
  const { location } = history;
  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <GlobalStyle />
            { location.pathname !== '/unauthorized/401' ? (
              <Layout style={{ minHeight: '100vh' }}>
                <Sidebar pathname={location.pathname}/>
                <Layout>
                  <HeaderCom/>
                  <Content style={{ margin: '0 16px' }}>
                    <Routes />
                  </Content>
                  <FooterCom/>
                </Layout>
              </Layout> 
              ) : (
                <Routes />
              )
            }
          </ErrorBoundary>
        </ThemeProvider>
      </Router>
    </Provider>
  )
}

export default App;
