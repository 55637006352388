import React from 'react';
import { Layout } from 'antd';
import config from 'config';

const { Footer } = Layout;

interface FooterProps {
}

export const FooterCom: React.FunctionComponent<FooterProps> = (props) => {
  const version = config.version || 'development';
  
  return (
    <Footer style={{ textAlign: 'center' }}>
      SCGC ©2022 - v.0.0.2(2022-03-18) | <span className='text-danger'>{version}</span>
    </Footer>
  );
}