import { reloadActionType } from '../actionTypes'

const reducers = (state = true, action) => {
    switch (action.type) {
        case reloadActionType.RELOAD:
            return action.payload;
        default:
            return state;
    }
}

export default reducers;